<template>
  <div class="m-3">
    <b-row>
      <b-col md="8">
        <b-card no-body>
          <b-card-header class="flex-column align-items-start">
            <b-card-title>ตัวเลือกการชำระเงิน</b-card-title>
            <b-card-text class="text-muted mt-25">
              อย่าลืมคลิกตัวเลือกการชำระเงินที่ถูกต้อง
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <!-- <h6 class="card-holder-name my-75">John Doe</h6> -->

            <!-- Radios -->
            <b-form-group>
              <!-- <b-form-radio
                name="payment-method"
                value="john-doe-debit-card"
                checked="john-doe-debit-card"
              >
                US Unlocked Debit Card 12XX XXXX XXXX 0000
              </b-form-radio> -->

              <!-- <hr class="mb-2 mt-1" /> -->

              <b-form-radio
                v-model="payment_method"
                name="type"
                class="mt-1"
                value="card_auto"
              >
                <img width="30px" :src="require('@/assets/visa.png')" alt="" />
                <img width="30px" :src="require('@/assets/visa2.png')" alt="" />
                บัตรเครดิต/บัตรเดบิต ตัด Auto
              </b-form-radio>
              <b-form-radio
                v-model="payment_method"
                name="type"
                class="mt-1"
                value="card"
              >
                <img width="30px" :src="require('@/assets/visa.png')" alt="" />
                <img width="30px" :src="require('@/assets/visa2.png')" alt="" />
                บัตรเครดิต/บัตรเดบิต
              </b-form-radio>
              <div style="margin-left: 40px" v-if="payment_method === 'card'">
                <hr />

                <div v-if="card_credit">
                  <b-form-radio
                    v-model="set_credit"
                    class="mt-1"
                    :value="card_credit"
                  >
                    <b>{{ card_credit }}</b>
                  </b-form-radio>
                </div>

                <div
                  @click="set_card(form_null, 0)"
                  v-if="count_card_credit === 0"
                >
                  <b-form-radio v-model="set_credit" class="mt-1" value="">
                    <b class="align-middle">เพิ่มบัตรเครดิต/บัตรเดบิต</b>
                  </b-form-radio>
                </div>
              </div>
              <b-form-radio
                v-model="payment_method"
                class="mt-1"
                value="QrCode"
                name="type"
              >
                <img width="20px" :src="require('@/assets/pp.png')" alt="" /> Qr
                code
              </b-form-radio>
              <b-form-radio
                v-model="payment_method"
                class="mt-1"
                value="installment"
                name="type"
              >
                ผ่อนชำระ
              </b-form-radio>
              <div
                style="margin-left: 40px"
                v-if="payment_method === 'installment'"
              >
                <b-form-radio
                  name="month"
                  v-model="month"
                  class="mt-1"
                  value="3"
                >
                  <b>3 เดือน</b>
                </b-form-radio>
                <b-form-radio
                  name="month"
                  v-model="month"
                  class="mt-1"
                  value="6"
                >
                  <b>6 เดือน</b>
                </b-form-radio>
                <b-form-radio
                  name="month"
                  v-model="month"
                  class="mt-1"
                  value="10"
                >
                  <b>10 เดือน</b>
                </b-form-radio>
                <hr />

                <div v-if="card_credit">
                  <b-form-radio
                    v-model="set_installment"
                    class="mt-1"
                    :value="card_credit"
                  >
                    <b>{{ card_credit }}</b>
                  </b-form-radio>
                </div>

                <div
                  @click="set_card(form_null, 0)"
                  v-if="count_card_credit === 0"
                >
                  <b-form-radio v-model="set_installment" class="mt-1" value="">
                    <b class="align-middle">เพิ่มบัตรเครดิต/บัตรเดบิต</b>
                  </b-form-radio>
                </div>
              </div>
            </b-form-group>
            <br />
            <hr class="my-2" />
          </b-card-body>
          <b-button
            v-if="set_credit && payment_method === 'card_auto'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            @click="omise_automatic_monthly_payments()"
          >
            ต่อไป ตัดผ่านตัด Auto
          </b-button>
          <b-button
            v-if="set_credit && payment_method === 'card'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            @click="omise_token_payments()"
          >
            ต่อไป ตัดผ่านตัด
          </b-button>
          <b-button
            v-if="payment_method === 'QrCode'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
          >
            ต่อไป QrCode
          </b-button>
          <b-button
            v-if="payment_method === 'installment'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            @click="omise_Create_installment_payment()"
          >
            ต่อไป installment
          </b-button>
        </b-card>
      </b-col>
      <!-- แสดง เพิ่มการ์ด -->
      <b-col md="4">
        <div
          class="amount-payable checkout-options"
          v-if="payment_method !== 'QrCode' && count_card_credit == 0"
        >
          <b-card>
            <b-alert variant="success" show>
              <div class="alert-body">
                <span>
                  <feather-icon icon="ShieldIcon" size="21" class="mr-75" />
                  ข้อมูลบัตรเครดิตของท่านได้ถูกเก็บรักษาด้วยการเข้ารหัส ซึ่งมีความปลอดภัยตามมาตรฐานสากล โดย Socio ไม่สามารถเข้าถึงข้อมูลบัตรเครดิตของท่านได้
                </span>
              </div>
            </b-alert>
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="หมายเลขบัตร"
                    label-for="payment-card-number"
                    class="mb-2"
                  >
                    <cleave
                      id="payment-card-number"
                      v-model="form.cardNumber"
                      class="form-control"
                      :raw="false"
                      :options="options.creditCard"
                      placeholder="2133 3244 4567 8921"
                    />
                  </b-form-group>
                </b-col>

                <b-col sm="6">
                  <b-form-group
                    label="เดือน/ปี ที่หมดอายุ"
                    label-for="expiry"
                    class="mb-2"
                  >
                    <cleave
                      v-model="form.expiry"
                      class="form-control"
                      :raw="false"
                      :options="options.expiry"
                      placeholder="99/99"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group label="รหัสหลังบัตร" label-for="cvv" class="mb-2">
                    <input
                      class="form-control"
                      v-model="form.cvv"
                      type="password"
                      placeholder="123"
                      maxlength="3"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="ชื่อเจ้าของบัตร"
                    label-for="name"
                    class="mb-2"
                  >
                    <b-form-input id="name" v-model="form.cardName" />
                  </b-form-group>
                </b-col>
                <br />
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="primary"
                    @click="save_card()"
                  >
                    บันทึก
                  </b-button>
                  <!-- <form id="checkoutForm" method="POST" action="/charge.php">
                    <input type="hidden" name="omiseToken" />
                    <input type="hidden" name="omiseSource" />

                    <button
                      class="btn btn-primary btn-block"
                      style="position: relative"
                      type="submit"
                      id="checkoutButton"
                      @click="set_card()"
                    >
                      Checkout
                    </button>
                  </form> -->
                </b-col>
              </b-row>
            </b-form>
            <hr />
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- <iframe  style="height:520px ;" src="https://manager.socio.co.th/sales/order" ></iframe> -->

    <!-- <iframe  style="height:520px ;" src="https://demo.buzzprivilegewifi.com/captive/home?hotspot=74-4D-28-0C-B7-6C&mac=A2%3AFC%3A19%3AC3%3A97%3AEB&nomodal=undefined&client_ip=192.168.182.251&lg=th" ></iframe> -->
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BAlert,
  BForm,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import api from "@/api";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
    BAlert,
    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: [],
      count_card_credit: 100,
      card_credit: null,
      month: 3,
      // form_null: {
      //   cardNumber: 4242424242424242,
      //   expiry: "0330",
      //   cvv: 123,
      //   cardName: "JOHN DOE",
      // },
      form: {
        cardNumber: null,
        expiry: null,
        cvv: null,
        cardName: null,
      },
      set_credit: null,
      set_installment: null,

      payment_method: "card",
      cardNumber: "",
      Remember_me: true,
      options: {
        creditCard: {
          creditCard: true,
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        expiry: {
          delimiter: " / ",
          blocks: [2, 2],
          uppercase: true,
        },
      },
    };
  },

  mounted() {
    this.loaddata();
  },
  methods: {
    async loaddata() {
      api
        .get("user_card_by_omise", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.count_card_credit = response.data.count_card_credit;
          this.card_credit = "****" + response.data.result.last_digits;
          this.set_credit = "****" + response.data.result.last_digits;
          this.set_installment = "****" + response.data.result.last_digits;
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
    set_card(data, status) {
      if (status === 0) {
        this.form.cardNumber = data.cardNumber;
        this.form.expiry = data.expiry;
        this.form.cvv = data.cvv;
        this.form.cardName = data.cardName;
      } else {
        this.form.cardNumber = null;
        this.form.expiry = null;
        this.form.cvv = null;
        this.form.cardName = null;
      }

      var omiseToken = document.querySelector('input[name="omiseToken"]');
    },
    save_card() {
      // console.log("this.form :>> ", this.form);
      if (
        this.form.cardNumber &&
        this.form.expiry &&
        this.form.cvv &&
        this.form.cardName
      ) {
        api
          .post("omise_addCustomers", this.form, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.loaddata();
            console.log("response.data :>> ", response.data);
            this.$swal({
              title: "Good job!",
              text: "You clicked the button!",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: " โปรดลองใหม่อีกครั้ง!",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      } else {
        this.$swal({
          title: "Error!",
          text: " ตรวจสอบข้อมูลให้เรียบร้อย!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },

    omise_token_payments() {
      const params = {
        product_id: this.$route.query.id,
      };
      api
        .post("omise_token_payments", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.$swal({
            title: "ชำระเงินสำเร็จ!",
            // text: "ทำรายการสำเร็จ!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: " โปรดลองใหม่อีกครั้ง!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    omise_automatic_monthly_payments() {
      const params = {
        product_id: this.$route.query.id,
      };
      api
        .post("omise_automatic_monthly_payments", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.$swal({
            title: "ชำระเงินสำเร็จ!",
            // text: "ทำรายการสำเร็จ!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: " โปรดลองใหม่อีกครั้ง!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    omise_Create_installment_payment() {
      const params = {
        product_id: this.$route.query.id,
      };
      api
        .post("omise_Create_installment_payment", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.$swal({
            title: "ชำระเงินสำเร็จ!",
            text: "สร้างรายการรับผ่อนชำระสินค้า!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: " โปรดลองใหม่อีกครั้ง!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
